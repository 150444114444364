import React from "react";
import styled from "styled-components"
import { graphql } from "gatsby";
import { ButtonLink } from "../components/ButtonLink";
import Layout from "../components/Layout";
import { Breadcrumbs } from "../components/Breadcrumbs";
import Main from "../components/Main";
import SideBar from "../components/SideBar";
import { ArticlePhotoRowLinks } from "../components/ArticlePhotoRowLinks";
import { Grid } from "@material-ui/core";
import theme, {breakpoints} from "../style/theme";

const PostsContainer = styled.div`
  position: relative;
  width: 100%;
  background-color: ${theme.palette.white};
  z-index: 1;
`;

const PostsWrapper = styled.div`
  width: 100%;
  max-width: ${breakpoints.lg}px;
  margin: 0 auto;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 0.5rem 0rem;
  margin: 2rem 0;
  box-sizing: border-box;
  @media screen and (max-width: ${breakpoints.sm}px) {
    display: block;
    padding: 0.5rem 0;
  }
`

export default function PostsTemplate({
  data: {
    site: {
      siteMetadata: {
        siteUrl,
        templates: {
          posts: {
            pathPrefix,
            filters: {
              posts: { totalPosts },
            },
          },
        },
      },
    },
    allMdx: { edges: posts },
  }
}) {
  return (
    <Layout title={`記事一覧`} shownAdSense={false}>
      <PostsContainer>
        <PostsWrapper>
          <Breadcrumbs
            links={[
              {
                url: null,
                title: 'BLOG',
              }
            ]}
          />
          <ArticlePhotoRowLinks
            edges={posts}
            fontColor={theme.palette.white}
            backgroundColor={theme.palette.primary.main}
            breakpoint={breakpoints.sm}
          />
          {posts.length === totalPosts && (
            <ButtonWrapper>
              <ButtonLink
                to={`/${pathPrefix}/page/1`}
              >
                記事を全て見る
              </ButtonLink>
            </ButtonWrapper>
          )}
        </PostsWrapper>
      </PostsContainer>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($limit: Int!) {
    site {
      siteMetadata {
        siteUrl
        templates {
          posts {
            pathPrefix
            filters {
              posts {
                totalPosts
              }
            }
          }
        }
      }
    }
    allMdx(
      filter: {
        fileAbsolutePath: { regex: "/content/posts/" }
      }
      sort: { order: DESC, fields: [fileAbsolutePath] }
      limit: $limit
    ) {
      edges {
        node {
          excerpt(pruneLength: 50, truncate: true)
          fileAbsolutePath
          frontmatter {
            id
            title
            category
            description
            tags
            createdDate
            updatedDate
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 720) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
